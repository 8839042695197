@tailwind base;
@tailwind components;
@tailwind utilities;

.summary {
    background-color: var(--fallback-n,oklch(var(--n)/var(--tw-bg-opacity))) !important
}

.markdown-body {
    background-color: var(--fallback-n,oklch(var(--n)/var(--tw-bg-opacity))) !important
}

.summary-head-border {
    border-bottom-color: var(--bgColor-default);
    border-bottom-width: 1px;
}